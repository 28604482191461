export const MY_COLORS = {
  red: "#FF453A",
  orange: "#FF0F0A",
  yellow: "#FFD60A",
  green: "#32D74B",
  lightBlue: "#64D2FF",
  blue: "#0A84FF",
  pink: "#FF374F",
  black: "#000000",
  gray: "#F5F5F7",
};
